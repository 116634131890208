export const AUTH_USER = 'AUTH_USER';
export const GET_MESSAGES = 'GET_MESSAGES';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const GET_PENDING = 'GET_PENDING';
export const REMOVE_MESSAGES = 'REMOVE_MESSAGES';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const FORM_EMPLOYEE = 'FORM_EMPLOYEE';
export const GET_LEARNING = 'GET_LEARNING';
export const CREATE_LEARNING = 'CREATE_LEARNING';
export const UPDATE_LEARNING = 'UPDATE_LEARNING';
export const DATA_FORM = 'DATA_FORM';
export const GET_FEEDBACKS = 'GET_FEEDBACKS';
export const CREATE_FEEDBACK = 'CREATE_FEEDBACK';
export const UPDATE_FEEDBACK = 'UPDATE_FEEDBACK';
export const DELETE_FEEDBACK = 'DELETE_FEEDBACK';
export const DELETE_LEARNING = 'DELETE_LEARNING';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const GET_FILTERS = 'GET_FILTERS';
export const CREATE_NEW = 'CREATE_NEW';
export const DELETE_NEW = 'DELETE_NEW';
export const UPDATE_NEW = 'UPDATE_NEW';
export const GET_NEWS = 'GET_NEWS';
export const GET_SERVICES = 'GET_SERVICES';
export const DELETE_SERVICE = 'DELETE_SERVICES';
export const GET_EVENTS = 'GET_EVENTS';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const GET_MULTIMEDIA = 'GET_MULTIMEDIA';
export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_MULTIMEDIA = 'CREATE_MULTIMEDIA';
export const DELETE_MULTIMEDIA = 'DELETE_MULTIMEDIA';
export const FILTER_BY_TITLE = 'FILTER_BY_TITLE';



