// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n  .styles_scroll__UilOE{\n    overflow-y:auto;\n    overflow-x:auto;\n    min-height: 290px !important;\n    max-height: 290px !important;\n}\n.styles_scroll__UilOE::-webkit-scrollbar {\n  width: 8px; \n  max-height: 8px;\n}\n.styles_scroll__UilOE::-webkit-scrollbar-thumb {\n    background: rgb(239, 7, 7);\n    border-radius: 0.25rem;\n}\n.styles_container__mBAvk{\n  overflow-y:auto;\n  overflow-x:auto;\n    scroll-behavior: smooth;\n    min-height: 300px !important;\n    max-height: 300px !important;\n    \n}\n.styles_container__mBAvk::-webkit-scrollbar {\n    width: 8px; \n    max-height: 8px;\n}\n  .styles_container__mBAvk::-webkit-scrollbar-thumb {\n      background: rgb(239, 7, 7);\n      border-radius: 0.25rem;\n  }\n", "",{"version":3,"sources":["webpack://./src/components/Reports/styles.module.css"],"names":[],"mappings":";EACE;IACE,eAAe;IACf,eAAe;IACf,4BAA4B;IAC5B,4BAA4B;AAChC;AACA;EACE,UAAU;EACV,eAAe;AACjB;AACA;IACI,0BAA0B;IAC1B,sBAAsB;AAC1B;AACA;EACE,eAAe;EACf,eAAe;IACb,uBAAuB;IACvB,4BAA4B;IAC5B,4BAA4B;;AAEhC;AACA;IACI,UAAU;IACV,eAAe;AACnB;EACE;MACI,0BAA0B;MAC1B,sBAAsB;EAC1B","sourcesContent":["\n  .scroll{\n    overflow-y:auto;\n    overflow-x:auto;\n    min-height: 290px !important;\n    max-height: 290px !important;\n}\n.scroll::-webkit-scrollbar {\n  width: 8px; \n  max-height: 8px;\n}\n.scroll::-webkit-scrollbar-thumb {\n    background: rgb(239, 7, 7);\n    border-radius: 0.25rem;\n}\n.container{\n  overflow-y:auto;\n  overflow-x:auto;\n    scroll-behavior: smooth;\n    min-height: 300px !important;\n    max-height: 300px !important;\n    \n}\n.container::-webkit-scrollbar {\n    width: 8px; \n    max-height: 8px;\n}\n  .container::-webkit-scrollbar-thumb {\n      background: rgb(239, 7, 7);\n      border-radius: 0.25rem;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll": "styles_scroll__UilOE",
	"container": "styles_container__mBAvk"
};
export default ___CSS_LOADER_EXPORT___;
