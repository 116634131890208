import React,{useEffect, useState} from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import {formEmployee, uploadFile} from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../Filter';
const CreateLearning = ({data})=> {
    let [form, setForm] = useState({
      name:'',
      body:'',
      author:'',
      date:'',
      tipo:'Organización',
      userLikes:[],
      userDislikes:[],
      previewImage:'',
      filters:{planta:[],segmentoPoblacion:[],razonSocial:[],puesto:[],ubicacion:[]}
    })
    
    const dispatch = useDispatch();
    const handleSubmit = (e,filter,all)=>{
      console.log(all)
      console.log('soy all')
         e.preventDefault()
        let prop = e.target.id;
        console.log(e.target.value)
        if(prop === 'name'){
            setForm({...form, name:e.target.value})
         form.name = e.target.value;
        }
        else if(prop === "author"){
          console.log(e.target.value)
          setForm({...form, author:e.target.value})
          form.author = e.target.value;
      }
        else if(prop === "body"){
            console.log(e.target.value)
            setForm({...form, body:e.target.value})
            form.body = e.target.value;
        }
        else if(prop === 'date'){
            setForm({...form, date:e.target.value})
            form.date = e.target.value;
        }
        else if(prop === 'tipo'){
          setForm({...form, tipo:e.target.value})
          form.tipo = e.target.value;
      }
        else if(prop === 'userLikes'){
            setForm({...form, userLikes:e.target.value})
            form.userLikes = e.target.value;
        }
        else if(prop === 'userDislikes'){
          setForm({...form, userDislikes:e.target.value})
          form.userDislikes = e.target.value;
      }
        else if(prop === 'previewImage'){
          
          
        
           let formI = new FormData();
           console.log(e.target.files[0])
           console.log("hola")
            formI.append('file',e.target.files[0])
            axios.post(`https://flexi.brounieapps.com/uploadd`,formI)
            .then(res=>{
              setForm({...form, previewImage:res.data.name})
              form.previewImage = res.data.name;
              setTimeout(()=>{
                dispatch(formEmployee(form))
              },1600)
            });
        }
         if(filter){
          let aux = {...form};
          let exist = aux.filters[filter.category].filter(item=>item===filter.value)
          if(exist.length>0){
            
           if(!filter.status){
             console.log("entro aqui")
            let removeResult = aux.filters[filter.category].filter(item=>item!==filter.value)
            
            aux.filters[filter.category]= removeResult
          }
          }else{
            aux.filters[filter.category]= aux.filters[filter.category].concat([filter.value])
          }
          setForm(aux)
        }
        if(all){
          let aux = {...form};
          aux.filters=all
          console.log("soy aux")
          console.log(aux)
          setForm(aux)
          form = aux;
          formEmployee(aux)
          setTimeout(()=>{
            dispatch(formEmployee(form))
          },1600)
         
        }
        setTimeout(()=>{
          dispatch(formEmployee(form))
        },1000)
      
       
    }
    useEffect(()=>{
       
    },[])
    return (
        <form onChange = {e=>handleSubmit(e)} class="row">
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Nombre</label>
          <input type="text" class="form-control" id="name" value = {form.name}/>
        </div>
         <div class="col-md-6">
         <label for="inputEmail4" class="form-label">Autor</label>
         <input type="text" class="form-control" id="author" value = {form.author}/>
       </div>
        <label for="inputPassword4" class="form-label">Descripcion</label>
        <div class="col-md-12">
          <textarea cols = '80' rows = '6' id="body" value = {form.body}></textarea>
        </div>
        <div class="col-6">
  
          <label for="inputAddress" class="form-label">Fecha</label>
          <input type="date" class="form-control" id="date" value = {form.date}/>
        </div>
        <div class="col-md-6">
          <label for="inputCity" class="form-label">Imagen</label>
          <input type="file" class="form-control" id='previewImage'/>
        </div>
        <div class="col-6 mt-2">
        <label for="inputAddress" class="form-label">Tipo de noticia</label>
        <select class="form-control" id="tipo" onChange={e=>handleSubmit(e)}>
  <option value="Industria">Industria</option>
  <option value="Organización" selected>Organización</option>
  <option value="Colaborador">Colaborador</option>
</select>
</div>
        <div class="col-12">
        <Filter data = {data} headers = {['Razón social','Segmento población','Planta','Puesto',"Ubicacion"]} handle = {handleSubmit}/>
        </div>
      </form>
    );
  }
  export default CreateLearning;