// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_scroll__HsVNM{\n    overflow-y:auto;\n    overflow-x:auto;\n}\n.styles_tableD__t6Pyc{\n\n}\nthead{\n    position: sticky;\n    top: 0;\n    bottom: 0;\n    background-color: rgb(255, 255, 255);\n}\ntbody{\n    background-color: rgb(252, 252, 246);\n}\n.styles_container__AHB0u{\n}\n.styles_tab__nCncT{\n    overflow: hidden;\n    overflow-y:auto;\n    overflow-x:auto;\n    max-height: 84vh;\n}\n\ntr th{\n text-align: center;\n\n}\ntr td{\n    text-align: center;\n    border-top: 1px solid;\n    border-color: rgba(187, 183, 183, 0.411);\n    font-size: 13px;\n   }", "",{"version":3,"sources":["webpack://./src/components/Users/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;AACnB;AACA;;AAEA;AACA;IAEI,gBAAgB;IAChB,MAAM;IACN,SAAS;IACT,oCAAoC;AACxC;AACA;IACI,oCAAoC;AACxC;AACA;AACA;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf,gBAAgB;AACpB;;AAEA;CACC,kBAAkB;;AAEnB;AACA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,wCAAwC;IACxC,eAAe;GAChB","sourcesContent":[".scroll{\n    overflow-y:auto;\n    overflow-x:auto;\n}\n.tableD{\n\n}\nthead{\n    position:-webkit-sticky;\n    position: sticky;\n    top: 0;\n    bottom: 0;\n    background-color: rgb(255, 255, 255);\n}\ntbody{\n    background-color: rgb(252, 252, 246);\n}\n.container{\n}\n.tab{\n    overflow: hidden;\n    overflow-y:auto;\n    overflow-x:auto;\n    max-height: 84vh;\n}\n\ntr th{\n text-align: center;\n\n}\ntr td{\n    text-align: center;\n    border-top: 1px solid;\n    border-color: rgba(187, 183, 183, 0.411);\n    font-size: 13px;\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll": "styles_scroll__HsVNM",
	"tableD": "styles_tableD__t6Pyc",
	"container": "styles_container__AHB0u",
	"tab": "styles_tab__nCncT"
};
export default ___CSS_LOADER_EXPORT___;
