// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.styles_container__YNmBX {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n  gap: 20px;\n  background: rgb(194, 6, 6);\n  padding: 15px;\n}\n.styles_container__YNmBX img {\n  width: 100%;\n  display: block;\n  filter: grayscale(1);\n  transition: all 100ms ease-out;\n}\n.styles_container__YNmBX img:hover {\n  transform: scale(1.04);\n  filter: grayscale(0);\n}\n.styles_z__13JYF{\nz-index: 1000;\n}\n\n\n\n\n\n\n", "",{"version":3,"sources":["webpack://./src/components/ControlledForm/styles.module.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,2DAA2D;EAC3D,SAAS;EACT,0BAA0B;EAC1B,aAAa;AACf;AACA;EACE,WAAW;EACX,cAAc;EAEd,oBAAoB;EACpB,8BAA8B;AAChC;AACA;EACE,sBAAsB;EAEtB,oBAAoB;AACtB;AACA;AACA,aAAa;AACb","sourcesContent":["\n.container {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n  gap: 20px;\n  background: rgb(194, 6, 6);\n  padding: 15px;\n}\n.container img {\n  width: 100%;\n  display: block;\n  -webkit-filter: grayscale(1);\n  filter: grayscale(1);\n  transition: all 100ms ease-out;\n}\n.container img:hover {\n  transform: scale(1.04);\n  -webkit-filter: grayscale(0);\n  filter: grayscale(0);\n}\n.z{\nz-index: 1000;\n}\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__YNmBX",
	"z": "styles_z__13JYF"
};
export default ___CSS_LOADER_EXPORT___;
