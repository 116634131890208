// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__m7pD8{\n    overflow: hidden;\n    overflow-y:auto;\n    scroll-behavior: smooth;\n    max-height: 170px;\n   \n    padding:0;\n    \n}\n.styles_container__m7pD8::-webkit-scrollbar {\n    width: 6px; \n    max-height: 8px;\n    \n}\n  .styles_container__m7pD8::-webkit-scrollbar-thumb {\n      background: rgb(239, 7, 7);\n      border-radius: 0.25rem;\n  }\n.styles_row__fklST{\n    align-items: center;\n    font-size: 12px;\n    margin:0;\n\n    \n}", "",{"version":3,"sources":["webpack://./src/components/Filter/styles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,uBAAuB;IACvB,iBAAiB;;IAEjB,SAAS;;AAEb;AACA;IACI,UAAU;IACV,eAAe;;AAEnB;EACE;MACI,0BAA0B;MAC1B,sBAAsB;EAC1B;AACF;IACI,mBAAmB;IACnB,eAAe;IACf,QAAQ;;;AAGZ","sourcesContent":[".container{\n    overflow: hidden;\n    overflow-y:auto;\n    scroll-behavior: smooth;\n    max-height: 170px;\n   \n    padding:0;\n    \n}\n.container::-webkit-scrollbar {\n    width: 6px; \n    max-height: 8px;\n    \n}\n  .container::-webkit-scrollbar-thumb {\n      background: rgb(239, 7, 7);\n      border-radius: 0.25rem;\n  }\n.row{\n    align-items: center;\n    font-size: 12px;\n    margin:0;\n\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__m7pD8",
	"row": "styles_row__fklST"
};
export default ___CSS_LOADER_EXPORT___;
